import { render, staticRenderFns } from "./OrderDescArrow.vue?vue&type=template&id=0e8b4564&"
import script from "./OrderDescArrow.vue?vue&type=script&lang=ts&"
export * from "./OrderDescArrow.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports